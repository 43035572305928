import { defineMessages } from 'react-intl';

export default defineMessages({
  returnFormItems: {
    id: `returnFormItems`,
    defaultMessage: 'Items',
  },
  returnFormReturns: {
    id: `returnFormReturns`,
    defaultMessage: 'Previous requests',
  },
  returnRequest: {
    id: `returnRequest`,
    defaultMessage: 'Return request',
  },
  returnFormOrder: {
    id: `returnFormOrder`,
    defaultMessage: 'Order',
  },
  returnFormOrderNumber: {
    id: `returnFormOrderNumber`,
    defaultMessage: 'Number: {number}',
  },
  returnFormCustomer: {
    id: `returnFormCustomer`,
    defaultMessage: 'Customer',
  },
  returnFormCustomerName: {
    id: `returnFormCustomerName`,
    defaultMessage: 'Name: {name}',
  },
  returnFormCustomerExtra: {
    id: `returnFormCustomerExtra`,
    defaultMessage: 'Extra: {extra}',
  },
  returnFormCustomerStreet: {
    id: `returnFormCustomerStreet`,
    defaultMessage: 'Street: {street}',
  },
  returnFormCustomerZipCity: {
    id: `returnFormCustomerZipCity`,
    defaultMessage: 'City: {city}',
  },
  returnFormCustomerCountry: {
    id: `returnFormCustomerCountry`,
    defaultMessage: 'Country: {country}',
  },
  returnFormCustomerEmail: {
    id: `returnFormCustomerEmail`,
    defaultMessage: 'Email: {email}',
  },
  positionNumber: {
    id: `positionNumber`,
    defaultMessage: 'Position',
  },
  itemName: {
    id: `itemName`,
    defaultMessage: 'Name',
  },
  itemNumber: {
    id: `itemNumber`,
    defaultMessage: 'Number',
  },
  totalQuantity: {
    id: `totalQuantity`,
    defaultMessage: 'Total quantity',
  },
  canceledQuantity: {
    id: `canceledQuantity`,
    defaultMessage: 'Canceled quantity',
  },
  returnableQuantity: {
    id: `returnableQuantity`,
    defaultMessage: 'Returnable quantity',
  },
  askedQuantity: {
    id: `askedQuantity`,
    defaultMessage: 'Quantity to return',
  },
  returnedQuantity: {
    id: `returnedQuantity`,
    defaultMessage: 'Returned quantity',
  },
  returnReason: {
    id: `returnReason`,
    defaultMessage: 'Reason',
  },
  status: {
    id: `status`,
    defaultMessage: 'Status',
  },
  statusReceived: {
    id: `statusReceived`,
    defaultMessage: 'Received',
  },
  statusPreparation: {
    id: `statusPreparation`,
    defaultMessage: 'Preparation',
  },
  statusShipped: {
    id: `statusShipped`,
    defaultMessage: 'Shipped',
  },
  statusDelivered: {
    id: `statusDelivered`,
    defaultMessage: 'Delivered',
  },
  statusCanceled: {
    id: `statusCanceled`,
    defaultMessage: 'Canceled',
  },
  statusNotDelivered: {
    id: `statusNotDelivered`,
    defaultMessage: 'Not delivered',
  },
  statusFollowUp: {
    id: `statusFollowUp`,
    defaultMessage: 'Follow up',
  },
  statusArranged: {
    id: `statusArranged`,
    defaultMessage: 'Arranged',
  },
  returnReason_LateDelivery: {
    id: `returnReason_LateDelivery`,
    defaultMessage: 'Late delivery',
  },
  returnReason_DontLikeAnymore: {
    id: `returnReason_DontLikeAnymore`,
    defaultMessage: "Don't like it anymore",
  },
  returnReason_MissingPartsOrAccessories: {
    id: `returnReason_MissingPartsOrAccessories`,
    defaultMessage: 'Missing parts or accessory',
  },
  returnReason_ItemBrokenOrDamaged: {
    id: `returnReason_ItemBrokenOrDamaged`,
    defaultMessage: 'Item broken or damaged',
  },
  returnReason_WrongItemDelivered: {
    id: `returnReason_WrongItemDelivered`,
    defaultMessage: 'Wrong/twice delivered/ordered',
  },
  returnReason_FoundCheaperPrice: {
    id: `returnReason_FoundCheaperPrice`,
    defaultMessage: 'Found cheaper price',
  },
  returnReason_ShippingPackageDamaged: {
    id: `returnReason_ShippingPackageDamaged`,
    defaultMessage: 'Shipping package damaged',
  },
  returnReason_WrongDescription: {
    id: `returnReason_WrongDescription`,
    defaultMessage: 'Item does not match the product description',
  },
  returnReason_ItemTooBigOrSmall: {
    id: `returnReason_ItemTooBigOrSmall`,
    defaultMessage: 'Item is too big/small',
  },
  returnReason_None: {
    id: `returnReason_None`,
    defaultMessage: 'Other reason',
  },
  created: {
    id: `created`,
    defaultMessage: 'Created',
  },
  processed: {
    id: `processed`,
    defaultMessage: 'Processed',
  },
  notReturned: {
    id: `notReturned`,
    defaultMessage: 'Not returned',
  },
  creating: {
    id: `creating`,
    defaultMessage: 'Creating',
  },
  returnFormCreateReturn: {
    id: `returnFormCreateReturn`,
    defaultMessage: 'Create a return',
  },
  returnFormCancel: {
    id: `returnFormCancel`,
    defaultMessage: 'Cancel',
  },
  returnFormSave: {
    id: `returnFormSave`,
    defaultMessage: 'Save',
  },
  apiCreateReturnSuccess: {
    id: `apiCreateReturnSuccess`,
    defaultMessage: 'Return created successfully',
  },
  returnFormValidationMessage: {
    id: `returnFormValidationMessage`,
    defaultMessage: 'Please ensure all fields are valid. \'Quantity to return\' must be greater than 0, not empty, and within the returnable quantity. A reason must also be selected.',
  },
  onlyDeliveredInfoMessage: {
    id: `onlyDeliveredInfoMessage`,
    defaultMessage: 'Only delivered items can be returned'
  },
  returnableQtyNotAvailableInfoMessage: {
    id: `returnableQtyNonAvailableInfoMessage`,
    defaultMessage: 'Returnable quantity not available'
  },
  returnFormDownload: {
    id: `returnFormDownload`,
    defaultMessage: 'Download',
  },
  isLoading: {
    id: `isLoading`,
    defaultMessage: 'Loading...',
  },
  returnCreatedSuccessTitle: {
    id: `returnCreatedSuccessTitle`,
    defaultMessage: 'Success!',
  },
  returnCreatedSuccessMessage: {
    id: `returnCreatedSuccessMessage`,
    defaultMessage: 'The return label will be created and sent to your email address',
  },
  returnCreatedSuccessConfirm: {
    id: `returnCreatedSuccessConfirm`,
    defaultMessage: 'OK',
  },
});
